<template>
  <div class="app-home">
    <div class="app-home-head">
      TECH DOC. EDITOR Import
      <div class="app-home-head-username">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="confirm"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>

    <div class="app-home-table">
      <div class="table-name">
        <span>{{dataType}} List</span>
        <el-button type="danger" @click="specTestClick" round>
          <template>
            <i class="el-icon-guide"></i>
            <span>Spec Test</span>
          </template>
        </el-button>
        <el-button type="primary" @click="handleCoaClick">
          <template>
            <i class="el-icon-plus"></i>
            <span>COA</span>
          </template>
        </el-button>
        <el-button type="primary" @click="handleFileClick">
          <template>
            <i class="el-icon-document"></i>
            <span>Library</span>
          </template>
        </el-button>
        <el-button type="warning" @click="handleVerifyListClick">
          <template>
            <span>Verify List</span>
          </template>
        </el-button>
        <el-button type="primary" @click="handleClick" v-if="dataType != 'TDS' && userInfo.operateStatus">
          <template>
            <i class="el-icon-plus"></i>
            <span>New</span>
          </template>
        </el-button>
      </div>

      <el-row style="padding: 10px">
        <el-col :span="6">
          <span>Product Name：</span>
          <!-- <el-input v-model="searchData.name" style="width:350px;"></el-input> -->
          <el-autocomplete
            clearable
            v-model="searchData.name"
            :fetch-suggestions="nameSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectName"
          ></el-autocomplete>
        </el-col>
        <el-col :span="6">
          <div>
            <span>Item Code：</span>
            <!-- <el-input v-model="searchData.code" style="width:350px;"></el-input> -->
            <el-autocomplete
              popper-class="select-option"
              :popper-append-to-body="false"
              clearable
              v-model="searchData.code"
              :fetch-suggestions="codeSearch"
              placeholder="Please Input"
              @input="handleSelectCode"
            ></el-autocomplete>
          </div>
        </el-col>
        <el-col :span="9">
          <div style="margin-right: 20px">
            <el-radio-group
              v-model="dataType"
              size="medium"
              @change="dataTypeChange"
            >
              <el-radio-button label="Spec"></el-radio-button>
              <el-radio-button label="SDS"></el-radio-button>
              <el-radio-button label="Composition"></el-radio-button>
              <el-radio-button label="TPS"></el-radio-button>
              <el-radio-button label="Conversion"></el-radio-button>
              <el-radio-button label="TDS"></el-radio-button>
              <el-radio-button label="Test Method"></el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="3">
          <div>
            <el-button @click="handleSearch('查询')" type="primary">Search</el-button>
            <el-button @click="handleSearch('重置')">Reset</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row style="padding: 10px;margin-left:50px;" v-if="multipleSelection.length>0 && dataType != 'Conversion' && dataType != 'TDS' && dataType != 'Test Method' && userInfo.operateStatus">        
        <el-col :span="2">
          <div>
            <el-button type="primary" @click="addToLibrary">Upload to ERP Library</el-button>
          </div>
        </el-col>
      </el-row>
      <div
        :class="[{ 'table-list': true }, screen_ratio]"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="Desperately loading..."
        style="height: 77vh"
      >
        <el-table v-if="dataType != 'Conversion' && dataType != 'TDS' && dataType != 'Test Method'"
          :data="tableData"
          style="width: 100%;"
          :header-cell-style="{ background: '#FAFAFA' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            type="index"
            label="Number"
            width="80"
          ></el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Product Name</span>
            </template>
            <template slot-scope="scope">
              <span v-if="scope.row.isSet">{{scope.row.productName}}</span>
              <span v-else>
                <span v-if="dataType == 'TPS'">{{scope.row.productName}}</span>
                <el-input v-else v-model="scope.row.productName" @input="changeInput($event,scope.$index,'productName')"></el-input>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="itemCode" label="Item Code">
            <template slot="header">
              <span>Item Code</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': itemCodeSort,
                }"
                @click="handleSort('itemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>

            <template slot-scope="scope">
              <span :style="{ color: isRepeat(scope.row.itemCode) }">{{
                scope.row.itemCode
              }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="dataType != 'TPS'">
            <template slot="header">
              <div style="text-align: center;">Product</div>
              <div style="text-align: center;">Description</div>
            </template>
            <template slot-scope="scope">
              <span v-if="scope.row.isSet">{{scope.row.productDescription}}</span>
              <span v-else>
                <span v-if="dataType != 'Spec'">{{scope.row.productDescription}}</span>
                <el-input v-else v-model="scope.row.productDescription" @input="changeInput($event,scope.$index,'productDescription')"></el-input>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="SupplierLotBatchNo" v-if="dataType == 'TPS'">
            <template slot="header">
              <div style="text-align: center;">Supplier</div>
              <div style="text-align: center;">Lot/Batch No.</div>
            </template>
          </el-table-column>
          <el-table-column prop="DracoLot" label="Draco Lot" v-if="dataType == 'TPS'">
          </el-table-column>          
          <el-table-column prop="verified" label="Verify" v-if="dataType != 'TPS'">
            <template slot="header">
              <span>Verify</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': verifiedSort,
                }"
                @click="handleSort('verified')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>

            <template slot-scope="scope">
              <i
                :class="verify_show(scope.row).icon"
                :style="{ color: verify_show(scope.row).color }"
              ></i>
            </template>
          </el-table-column>
          <el-table-column prop="editName" label="Editor" v-if="dataType == 'Spec' || dataType == 'SDS'"></el-table-column>
          <el-table-column prop="editName" label="Edit Name" v-else></el-table-column>
          <el-table-column prop="verifiedName" label="Verified by" v-if="dataType == 'Spec' || dataType == 'SDS'"></el-table-column>
          <el-table-column prop="verifiedName" label="Verified Name" v-else-if="dataType != 'TPS'"></el-table-column>
          <el-table-column prop="libraryRevNumber" label="Uploaded Version" v-if="dataType != 'TPS'" align="center">
            <template slot="header">
              <div style="text-align: center;">Uploaded</div>
              <div style="text-align: center;">Rev#</div>
              <!-- <div style="text-align: center;">/Latest Rev#</div> -->
            </template>
          </el-table-column>

          <!-- <el-table-column
            prop="verifiedTime"
            label="Verified Time"
            width="140"
          >
            <template slot="header">
              <span>Verified Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': verifiedTimeSort,
                }"
                @click="handleSort('verifiedTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.verifiedTime) }}</span>
            </template>
          </el-table-column> -->

          <el-table-column prop="createTime" label="Create Time" width="140">
            <template slot="header">
              <span>Create Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': createTimeSort,
                }"
                @click="handleSort('createTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="updateTime" label="Update Time" width="140">
            <template slot="header">
              <span>Update Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': updateTimeSort,
                }"
                @click="handleSort((dataType == 'Spec' || dataType == 'SDS')?'verifiedTime':'editTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime((dataType == 'Spec' || dataType == 'SDS')?scope.row.verifiedTime:scope.row.updateTime) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Action" width="220px">
            <template slot-scope="scope">
              <span v-if="dataType !='TPS' && userInfo.operateStatus">
                <span class="table-edit" v-if="scope.row.isSet" @click="handleEdit(scope.$index)">Update</span>
                <span class="table-edit" v-else @click="saveEdit(scope.$index)">Save</span>
              </span>
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">Edit</span>
              <!-- <span
                v-if="(dataType === 'Spec' || dataType === 'SDS') && userInfo.operateStatus"
                class="table-edit"
                @click="handleClick(scope.row, '复制')"
                >Copy</span
              > -->
              <span
                v-if="
                  username === 'JamesChen' ||
                  username === 'admin' ||
                  username === 'JiaZhang'
                "
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="handleClick(scope.row, '删除')"
                >Delete</span
              >
            </template>
          </el-table-column>
        </el-table>

        <el-table v-if="dataType == 'Conversion' || dataType == 'TDS'"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column v-if="dataType == 'Conversion'">
            <template slot="header">
              <span>Item Code</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': newItemCodeSort,
                }"
                @click="handleSort('newItemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.newItemCode }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="dataType == 'Conversion'">
            <template slot="header">
              <span>Old Item Code</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': oldItemCodeSort,
                }"
                @click="handleSort('oldItemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.oldItemCode }}</span>
            </template>
          </el-table-column> 
          <el-table-column v-if="dataType == 'TDS'">
            <template slot="header">
              <span>Item Code</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': itemCodeSort,
                }"
                @click="handleSort('itemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.itemCode }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="Product Name"></el-table-column>
          <el-table-column prop="createTime" label="Date" width="140">
            <template slot="header">
              <span>Date</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': createTimeSort,
                }"
                @click="handleSort('createTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="confirmed" label="Confirmed">
            <template slot-scope="scope">
              <i v-if="scope.row.confirmed==1" class="el-icon-circle-check" style="color:#67c23a;"></i>
            </template>
          </el-table-column>
          <el-table-column prop="note" label="Note"></el-table-column>
          <el-table-column label="Action">
            <template slot-scope="scope">
              
              <i v-if="scope.row.confirmed==2" class="el-icon-s-flag" style="color:red;font-size: 20px;"></i>
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">Edit</span>
              <span
                v-if="username === 'JamesChen'"
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="handleClick(scope.row, '删除')"
                >Delete</span>
              <el-button v-if="dataType == 'Conversion' && scope.row.confirmed==1" type="info" @click="handleTdsClick(scope.row)">TDS</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-table v-if="dataType == 'Test Method'"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="methodCode" label="Method Code"></el-table-column>
          <el-table-column prop="methodName" label="Method Name"></el-table-column>
          <el-table-column prop="usage" label="Usage"></el-table-column>
          <el-table-column prop="reference" label="Reference"></el-table-column>
          <el-table-column prop="confirmed" label="Confirmed">
            <template slot-scope="scope">
              <i v-if="scope.row.confirmed==1" class="el-icon-circle-check" style="color:#67c23a;"></i>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="180px">
            <template slot-scope="scope">
              <i class="el-icon-s-flag" style="color:red;font-size: 20px;" v-if="scope.row.confirmed==2"></i>
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">Edit</span>
              <span
                v-if="username === 'JamesChen'"
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="handleClick(scope.row, '删除')"
                >Delete</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="table-pagition">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageIndex"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";

export default {
  name: "home",
  mixins: [base],
  data() {
    return {
      loading: false,
      dataType: "Spec",
      tableData: [],
      total: 0,
      params: {
        pageIndex: 1,
        pageSorts: [
          {
            column: "createTime",
            asc: false,
          },
        ],
        pageSize: 100,
        itemCode: "",
        productName: "",
        verifiedList:0,
      },
      searchData: {
        name: "",
        code: "",
      },
      preview: {},
      createTimeSort: true,
      updateTimeSort: true,
      verifiedTimeSort: true,
      verifiedSort: true,
      itemCodeSort: true,
      newItemCodeSort: true,
      oldItemCodeSort: true,
      screen_ratio: "",
      nameList: [],
      codeList: [],
      getSearchList: true,
      itemCodeListStringify:"",
      multipleSelection: [],
    };
  },
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      localStorage.setItem("loginUserNameKey", userInfo.username);
      if (userInfo) {
        return userInfo.username;
      } else {
        return "";
      }
    },
  },
  methods: {
    specTestClick(){
      const isDomainValid = window.location.hostname === "cn.spec.dracoherbs.com";
      
      localStorage.setItem("dataType", 'Spec');
      if(isDomainValid){
        this.$router.push({ path: "home2",query:{language:"CN" } });
      }else{
        this.$router.push({ path: "home2",query:{language:"EN" } });
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(this.multipleSelection)
    },
    handleTdsClick(val){
      this.$router.push({ path: "new-item-tds", query: { convId: val.id }});
    },
    addToLibrary(){
      this.$confirm(
        "Are you sure batch add. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        let params ={ids:[],type:''}
        let ids=[]
        let type =''
        if (this.dataType === "Spec") {
          type = 'spec'
        }
        else if (this.dataType == "SDS") {
          type = 'sds'
        }
        else if (this.dataType == "Composition") {
          type = 'composition'
        }
        else if (this.dataType == "TPS") {
          type = 'tps'
        }
        let nAdd=""
        this.multipleSelection.forEach(item=>{
          if(item.verified=="true"){
            ids.push(item.id)
          }
          else{
            nAdd+=item.itemCode+ ","
          }
        })
        console.log('addToLibrary---notAdd======'+nAdd)
        if(ids.length>0){
          params.ids = ids
          params.type = type
          console.log('addToLibrary======params')
          console.log(params)
          this.$api
            .batchAddLibrary(params)
            .then(() => {
              this.$message({ type: "success", message: "batch Add SuccessFully" });
              this.getData(this.params);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        else{
          this.$message({ type: "error", message: "Please select the Verifyed" });
        }
      });
    },
    handleSelectName() {
      // this.handleSearch("查询");
    },
    handleSelectCode() {
      // this.handleSearch("查询");
    },
    nameSearch(queryString, cb) {
      if(typeof(this.itemCodeListStringify)==undefined){
        return;
      }
      var codeList = JSON.parse(this.itemCodeListStringify);
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.productName });
      });
      // var restaurants = this.nameList;
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    codeSearch(queryString, cb) {
      var codeList = JSON.parse(this.itemCodeListStringify);
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    isRepeat(val) {
      let isRepeat = 0;
      this.tableData.forEach((v) => {
        if (val == v.itemCode) {
          isRepeat++;
        }
      });
      if (isRepeat > 1) {
        if (this.dataType === "Spec" || this.dataType === "SDS") {
          return "red";
        }
        else{
          return "black";
        }
      } else {
        return "black";
      }
    },
    getTime(time) {
      if (time) {
        return this.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
    logout() {
      let token = localStorage.getItem("specToken");
      let id = JSON.parse(localStorage.getItem("specUserInfo")).id;
      this.$api
        .logout({ id, token })
        .then(() => {
          this.$router.replace({ path: "/login" });
          this.$message({ type: "success", message: "Logout SuccessFully" });
          localStorage.clear("specToken");
          localStorage.clear("specUserInfo");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirm() {
      this.$confirm("Are you sure to quit?", "Tips", {
        confirmButtonText: "Confrim",
        cancelButtonText: "Cancel",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        this.logout();
      });
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getData(this.params);
    },
    handleClick(val, type) {
      switch (type) {
        case "删除":
          {
            this.$confirm(
              "This will permanently delete the data. Continue?",
              "Tips",
              {
                confirmButtonText: "Ok",
                cancelButtonText: "Cancel",
                distinguishCancelAndClose: true,
                type: "warning",
              }
            ).then(() => {
              this.deleteItem(val);
            });
          }
          break;
        case undefined:
        case "编辑":
          {
            localStorage.setItem("searchDataParamsKey", JSON.stringify(this.params));
            if (this.dataType === "Spec") {
              this.$router.push({ path: "new-item", query: { id: val.id } });
            } 
            else if (this.dataType === "SDS") {
              this.$router.push({
                path: "new-item-sds",
                query: { id: val.id },
              });
            } 
            else if (this.dataType === "Composition") {
              this.$router.push({
                path: "new-item-comp",
                query: { id: val.id },
              });
            } 
            else if (this.dataType === "Conversion") {
              this.$router.push({
                path: "new-item-conversion",
                query: { id: val.id },
              });
            }  
            else if (this.dataType === "TDS") {
              this.$router.push({
                path: "new-item-tds",
                query: { id: val.id },
              });
            } 
            else if (this.dataType === "Test Method") {
              this.$router.push({
                path: "new-item-method",
                query: { id: val.id },
              });
            } 
            else {
              let data = JSON.stringify(val.rowData);
              localStorage.setItem("tpsDataKey", data);
              this.$router.push({
                path: "new-item",
                query: { id: val.id, type: "TPS" },
              });
            }
          }
          break;
        case "复制":
          {
            this.$confirm("This will Copy the data. Continue?", "Tips", {
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
              distinguishCancelAndClose: true,
              type: "warning",
            }).then(() => {
              if (this.dataType === "Spec") {
                this.copyItem(val);
              }
              else if (this.dataType === "SDS") {
                this.copySdsItem(val);
              }
              // else if (this.dataType === "Composition") {
              //   this.copyCompItem(val);
              // }
            });
          }
          break;
        default:
          break;
      }
    },
    deleteItem(val) {
      if (this.dataType === "Spec") {
        this.$api.handleSpec
          .deleteItem(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      } 
      else if (this.dataType === "SDS") {
        this.$api.handleSpec
          .deleteSdsItem(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else if (this.dataType === "Composition") {
        this.$api.handleComp
          .deleteCompItem(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else if (this.dataType === "TPS") {
        this.$api.handleTps
          .deleteTpsItem(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else if (this.dataType === "Conversion") {
        this.$api.handleConversion
          .conversionDelete(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else if (this.dataType === "TDS") {
        this.$api.handleTds
          .tdsDelete(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else if (this.dataType === "Test Method") {
        this.$api.handleTestMethod
          .testMethodDelete(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    copyItem(val) {
      this.$api.handleSpec
        .copyItem(val.id)
        .then((data) => {
          console.log(data);
          this.getData(this.params);
          this.$message({ type: "success", message: "Copy SuccessFully" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    copySdsItem(val) {
      this.$api.handleSpec
        .copySdsItem(val.id)
        .then((data) => {
          console.log(data);
          this.getData(this.params);
          this.$message({ type: "success", message: "Copy SuccessFully" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    copyCompItem(val) {
      this.$api.handleComp
        .copyCompItem(val.id)
        .then((data) => {
          console.log(data);
          this.getData(this.params);
          this.$message({ type: "success", message: "Copy SuccessFully" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dataTypeChange() {
      localStorage.setItem("listDataTypeKey", this.dataType);
      this.getData(this.params);
      console.info('dataTypeChange')
      this.getItemCodeListData({});
      // location.reload();
    },
    getData(params) {
      this.loading = true;
      if (this.dataType === "Spec") {
        this.getSpecData(params);
        return;
      }
      if (this.dataType == "SDS") {
        this.getSdsData(params);
        return;
      }
      if (this.dataType == "Composition") {
        this.getCompositionData(params);
        return;
      }
      if (this.dataType == "TPS") {
        this.getTpsData(params);
        return;
      }
      if (this.dataType == "Conversion") {        
        this.getConversionData(params);
        return;
      }
      if (this.dataType == "TDS") {
        this.getTdsData(params);
        return;
      }
      if (this.dataType == "Test Method") {
        this.getTestMethodData(params);
        return;
      }
      this.loading = false;
      return;
    },
    getSpecData(params) {
      this.$api
        .getSpecTable(params)
        .then((data) => {
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.tableData.forEach(item=>{
            item.isSet = true
          })
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getSdsData(params) {
      this.$api
        .getSdsTable(params)
        .then((data) => {
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.tableData.forEach(item=>{
            item.isSet = true
            if(item.jsonValue){
              let jsonValue = JSON.parse(item.jsonValue)
              item.libraryRevNumber = jsonValue.libraryRevNumber
            }
          })
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getCompositionData(params) {
      this.$api.handleComp
        .getCompTable(params)
        .then((data) => {
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.tableData.forEach(item=>{
            item.isSet = true
          })
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getTpsData(params) {
      console.log("getTpsData");
      console.log(params);
      this.$api.handleTps
        .getOtherTable(params)
        .then((data) => {
          let listData = [];
          console.log(data)
          data.records.forEach((element) => {
            let jsonValueStr =element.jsonValue.replace(new RegExp('&quot;', 'gm'), '"');
            let jsonValue = JSON.parse(jsonValueStr);
            let item = {
              id: element.id,
              createName: element.createName,
              editName: element.editName,
              productName: jsonValue.DracoProductName,
              SupplierLotBatchNo: jsonValue.SupplierLotBatchNo,
              itemCode: jsonValue.ItemCode,
              createTime: element.createTime,
              updateTime: element.updateTime,
              rowData: jsonValue,
              DracoLot:jsonValue.DracoItemCode
            };
            item.jsonValue = jsonValue
            listData.push(item);
          });
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = listData;
          console.log('tps===========this.tableData')
          console.log(this.tableData)
          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              let jsonValueStr1 =v.jsonValue.replace(new RegExp('&quot;', 'gm'), '"');
              let jsonValue1 = JSON.parse(jsonValueStr1);
              return { value: jsonValue1.DracoProductName };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getConversionData(params) {
      console.log("getConversionData");
      let par ={
        language:2,
        itemCode:params.itemCode,
        productName:params.productName,
        pageIndex:params.pageIndex,
        pageSize:params.pageSize,
        pageSorts:params.pageSorts
      }
      // params.language=2
      this.$api.handleConversion
        .getConversionList(par)
        .then((data) => {
          console.log(data)
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getTdsData(params) {
      console.log("getTdsData");
      params.language=2
      this.$api.handleTds
        .getTdsList(params)
        .then((data) => {
          console.log("getTdsData");
          console.log(data)
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getTestMethodData(params) {
      console.log("getTestMethodData");
      params.language=2
      this.$api.handleTestMethod
        .getTestMethodList(params)
        .then((data) => {
           console.log(data)
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSearch(type) {
      switch (type) {
        case "重置":
          {
            this.searchData = { name: "", code: "" };
          }
          break;
        case "查询":
          {
            let { name, code } = this.searchData;
            this.params.itemCode = code;
            this.params.productName = name;
            this.params.pageIndex = 1;

            // localStorage.setItem('searchParamKey',JSON.stringify({name,code}))
            this.getData(this.params);
          }
          break;
        default:
          break;
      }
    },
    getPreview(params) {
      this.$api
        .getPreview(params)
        .then((data) => {
          let obj = {
            NewestTime: "",
            NewestCode: "",
            spenTotal: "",
          };
          this.preview = data || obj;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    verify_show(val) {
      switch (val.verified) {
        case "true":
          return { icon: "el-icon-success", color: "#67c23a" };
        case "false":
          return { icon: "el-icon-error", color: "#f56c6c" };
        default:
          return { icon: "el-icon-question", color: "#a6a9ad" };
      }
    },
    handleSort(val) {
      switch (val) {
        case "createTime":
          {
            this.createTimeSort = !this.createTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.createTime).getTime();
              let bTime = new Date(b.createTime).getTime();
              return this.createTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "editTime":
          {
            this.updateTimeSort = !this.updateTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.updateTime).getTime();
              let bTime = new Date(b.updateTime).getTime();
              return this.updateTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "verifiedTime":
          {
            this.verifiedTimeSort = !this.verifiedTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.verifiedTime).getTime();
              let bTime = new Date(b.verifiedTime).getTime();
              return this.verifiedTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "itemCode":
          {
            this.itemCodeSort = !this.itemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.itemCode;
              let bitemCode = b.itemCode;
              return this.itemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        case "newItemCode":
          {
            this.newItemCodeSort = !this.newItemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.newItemCode;
              let bitemCode = b.newItemCode;
              return this.newItemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        case "oldItemCode":
          {
            this.oldItemCodeSort = !this.oldItemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.oldItemCode;
              let bitemCode = b.oldItemCode;
              return this.oldItemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        case "verified":
          {
            this.verifiedSort = !this.verifiedSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.verified == undefined ? "" : a.verified;
              let bitemCode = b.verified == undefined ? "" : b.verified;
              return this.verifiedSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        default:
          break;
      }
    },
    getItemCodeListData(params) {
      console.info('getItemCodeListData')
      if (this.dataType === "Spec") {
        this.getSpecItemCodeListData(params);
        return;
      }
      if (this.dataType == "SDS") {
        console.info('sds->')
        this.getSdsItemCodeList(params);
        return;
      }
      if (this.dataType == "Composition") {
        this.getCompItemCodeListUrl(params);
        return;
      }
      if (this.dataType == "TPS") {
        this.getTpsItemCodeListUrl(params);
        return;
      }
      this.getSpecItemCodeListData(params);
      return;
    },
    getSpecItemCodeListData(params) {
      this.$api
        .getItemCodeList(params)
        .then((data) => {
          localStorage.setItem("itemCodeListKey", JSON.stringify(data));
          this.itemCodeListStringify = JSON.stringify(data)
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getSdsItemCodeList(params) {
      this.$api
        .getSdsItemCodeListUrl(params)
        .then((res) => {
          console.log("itemCodeSdsListKey")
          localStorage.setItem("itemCodeSdsListKey", JSON.stringify(res));
          this.itemCodeListStringify = JSON.stringify(res)
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getTpsItemCodeListUrl(params) {
      this.$api.handleTps
        .getTpsItemCodeListUrl(params)
        .then((data) => {
          console.log("itemCodeTpsListKey")
          console.log(data)
          localStorage.setItem("itemCodeTpsListKey", JSON.stringify(data));
          this.itemCodeListStringify = JSON.stringify(data)
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getCompItemCodeListUrl(params) {
      this.$api.handleComp
        .getCompItemCodeListUrl(params)
        .then((data) => {
          console.log("itemCodeCompListKey")
          console.log(data)
          localStorage.setItem("itemCodeCompListKey", JSON.stringify(data));
          this.itemCodeListStringify = JSON.stringify(data)
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    requestGetAllName(params){
      this.$api.handleSpec
        .getAllNameCount(params)
        .then((data) => {
          console.log("specALLNameCount")
          console.log('allname-->',data)
          let specCount = localStorage.getItem("specALLNameCount")
          if(specCount == null || specCount == undefined || parseInt(specCount) != data){
            this.getAllName(params)
          }
          localStorage.setItem("specALLNameCount",data);
        })
        .catch((err) => {
          console.log(err);
      });
    },
    getAllName(params){
      this.$api.handleSpec
        .getAllName(params)
        .then((data) => {
          console.log("getAllName")
          var commonNameList=[]
          var inciNameList=[]
          var botanicalNameList=[]
          data.forEach(element => {
            commonNameList.push(element.commonName)
            inciNameList.push(element.inciName)
            botanicalNameList.push(element.botanicalName)
          });
          localStorage.setItem("specALLName",this.formatTime(new Date(),"yyyy-MM-dd hh:mm"));
          localStorage.setItem("commonNameList",JSON.stringify(commonNameList));
          localStorage.setItem("inciNameList",JSON.stringify(inciNameList));
          localStorage.setItem("botanicalNameList",JSON.stringify(botanicalNameList));
        })
        .catch((err) => {
          console.log(err);
      });
    },
    handleCoaClick(){
      this.$router.push({ path: "coa-items"});
    },
    handleFileClick(){
      this.$router.push({ path: "file"});
    },
    handleVerifyListClick(){
      this.$router.push({ path: "verify-list"});
    },
    handleEdit(index) {
      for (const i of this.tableData) {
        if (!i.isSet) return this.$message.warning('Please save the current edit item first')
      }
      this.tableData.forEach((element,i)=>{
        if(i==index){
          element.isSet = false;
          this.$set(this.tableData,i,element);
        }
      })
    },
    saveEdit(index) {
      this.tableData.forEach((element,i)=>{
        if(i==index){
          element.isSet = true;
          console.log(element)
          let params={
            id:element.id,
            productName:element.productName,
            productDescription:element.productDescription,
            SupplierLotBatchNo:""
          }
          console.log(params);
          if (this.dataType === "Spec") {
            this.$api.handleSpec.updateDetailBySpecList(params)
            .then(() => {
              this.$message({
                type: "success",
                message: "SuccessFul",
              });
              this.$set(this.tableData,i,element);
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
              });
              console.log(err);
            });
            return;
          }
          else if (this.dataType == "SDS") {
            this.$api.handleSpec.updateDetailBySdsList(params)
            .then(() => {
              this.$message({
                type: "success",
                message: "SuccessFul",
              });
              this.$set(this.tableData,i,element);
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
              });
              console.log(err);
            });
            return;
          }
          else if (this.dataType == "Composition") {
            this.$api.handleComp.updateDetailByCompList(params)
            .then(() => {
              this.$message({
                type: "success",
                message: "SuccessFul",
              });
              this.$set(this.tableData,i,element);
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
              });
              console.log(err);
            });
            return;
          }
        }
      })
    },
    changeInput(e,index,type){
      console.log(e)
      this.tableData.forEach((element,i)=>{
        if(i==index){
          switch (type) {
            case "productName":
              element.productName = e;
              break;
            case "productDescription":
              element.productDescription = e;
              break;
            default:
              return;
          }
          this.$set(this.tableData,i,element);
        }
      })
    },
  },
  mounted() {
    console.info('mounted---')
    this.getItemCodeListData({});
    this.requestGetAllName({});
  },
  created() {
    let type = localStorage.getItem("listDataTypeKey");
    if (type == null || typeof type == "undefined") {
      this.dataType = "Spec";
    } else {
      this.dataType = type;
    }
    console.info('-----this.getData(this.params);---')
    let searchDataParams = localStorage.getItem("searchDataParamsKey");
    if(searchDataParams){
      console.info(searchDataParams)
      this.params = JSON.parse(searchDataParams);
      this.searchData = { name: this.params.productName, code: this.params.itemCode };
    }
    this.getData(this.params);
    // this.getPreview();
    let sheight = window.screen.height;
    let swidth = window.screen.width;
    let ratio = swidth / sheight;
    switch (ratio) {
      case 16 / 9:
        this.screen_ratio = "ratio-16-9";
        break;
      case 16 / 10:
        this.screen_ratio = "ratio-16-10";
        break;
      case 3 / 2:
        this.screen_ratio = "ratio-3-2";
        break;
      case 4 / 3:
        this.screen_ratio = "ratio-4-3";
        break;
      default:
        this.screen_ratio = "ratio-16-10";
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .select-option {
  min-width: 300px;
}
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
</style>